/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageChannels/pageChatbots/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageChannels/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageChannels/pageChatbots/experience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageChannels/pageChatbots/titleUnderline/titleUnderlineAccordionLeft"
import { TitleUnderlineTestimonyOne, TitleUnderlineTestimonyTwo } from "@components/pageChannels/pageChatbots/titleUnderline/titleUnderlineTestimony"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"
import educacion from "@components/sharedComponents/testimonies/images/iconos/educacion.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Industries from "@components/pageChannels/pageChatbots/industries/industries"

const TemplatePageChatbots = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p>
    Con Beex, te ayudamos a <TitleUnderlineLeftOne underline="automatizar tus" /> <TitleUnderlineLeftTwo underline="conversaciones" /> para generar más conversiones
  </p>
  const titleTestimony = <p>
    Ayudamos a diferentes <TitleUnderlineTestimonyTwo underline="diferentes" /> <TitleUnderlineTestimonyOne underline="sectores" /> a fortalecer la relación con sus clientes con respuestas automatizadas

  </p>


  const iconsSilder = i => {
    switch (i) {
      case 0:
        return ecommerce
      case 1:
        return concesionarias
      case 2:
        return finanzas
      case 3:
        return salud
      case 4:
        return educacion
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Ecommerce"
      case 1:
        return "Concesionarias"
      case 2:
        return "Finanzas"
      case 3:
        return "Salud"
      case 4:
        return "Educación"
      default:
        return "Conoce otros casos de éxito"
    }
  }


  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/fK7S-a55Ab8?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} background={"#EEEAFB"} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Sé parte de las empresas que brindan experiencias conversacionales únicas" />
          <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={""} />
          <BannerCTA
            title="Crea experiencias conversacionales únicas entre tu empresa y tus clientes"
            text="Mira en tiempo real como pueden ayudarte nuestros chatbots en tu servicio, marketing o ventas."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Aumenta las conversiones de tu empresa con conversaciones únicas"
            text="Nuestro equipo comercial está dispuesto a ayudarte con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Industries data={data.industries} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageChatbots
